import React from "react";
import styled from "styled-components";
import { Container } from "../global-style";
import useProjectsdata from "../SiteProjects";
import ReactSVG from "react-svg";

const MainLogoContainer = styled.section`
  background: black;
`;
const LogoWrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  img {
    margin-right: 1rem;
    width: 6rem;
  }
`;
const Logos = () => {
  const logos = useProjectsdata().map(
    item => item.node.frontmatter
  );
  const clients = [];

const uniqLogos = logos.map(item=>{
  if (!clients.includes(item.client)) {
    clients.push(item.client);
    return item.logo.publicURL;
  }
}).filter(Boolean);

  return (
    <MainLogoContainer>
      <Container>
        <LogoWrapper>
          {uniqLogos.map((logo, index) => {
            return <img key={index} src={logo} />;
          })}
        </LogoWrapper>
      </Container>
    </MainLogoContainer>
  );
};
export default Logos;
