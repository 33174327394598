import React from "react";
import styled from "styled-components";
import { Container, UnderLine } from "../global-style";
import { Link } from "gatsby";
import Arrow from "../../img/svg/arrow.svg";
const AboutContainer = styled.section`
  background: var(--tbwa-gray);
  h2 {
    color: var(--tbwa-yellow);
  }
  .grid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .grid > div {
    max-width: 375px;
  }
  .grid .text {
    font-size: 0.8rem;
  }
`;

const About = props => {
  return (
    <AboutContainer>
      <Container>
        <h1>A propos</h1>
        <UnderLine />
        <div className="grid">
          <div>
            <h2>We Are The Disruption<sup>®</sup> Company </h2>
            <h4>
              Nous aidons nos clients à créer de la différence. Nous créons des
              marques fortes. Nous enrichissons et protégeons les réputations.
            </h4>
          </div>
          <div className="text">
            <p>
              Chez TBWA\ DJAZ, nous croyons que la communication n’est pas un «
              luxe », mais un des piliers de la réussite en affaires. Nous
              sommes des créateurs de différence, ce qui à son tour crée de la
              valeur pour les entreprises comme pour les consommateurs.
            </p>
            <p>
              C'est pour cela que depuis plus de 15 ans, nous combinons la
              puissance de la Disruption, la méthodologie phare du collectif
              TBWA, avec notre connaissance intime des marchés et des
              consommateurs nord-africains pour apporter de la valeur tangible à
              nos clients.
            </p>
            <Link to="/about">
              <img src={Arrow} />
            </Link>
          </div>
        </div>
      </Container>
    </AboutContainer>
  );
};
export default About;
