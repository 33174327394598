import React from "react";
import { UnderlineHeader, Container } from "../global-style";
import styled from "styled-components";
import PeopleIcon from "../../img/svg/people.svg";
import Backslash from "../../img/svg/backslash-yellow.svg";
import { Link } from "gatsby";

const ExpertiseContainer = styled.section`
  a {
    color: black;
    &:hover {
      color: var(--tbwa-yellow);
    }
  }
  .feature {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover {
      img {
        opacity: 1;
      }
    }
  }
  .feature-img img {
    width: 60px;
    opacity: 0;
    margin: 0 1rem;
    height: 100%;
    display: block;
  }
  @media (max-width: 800px) {
    h4{
      font-size: 0.8rem;
    
    }
    p{
      font-size:0.6rem;
    }
   
  }
`;
const Expertise = props => {
  return (
    <Container>
      <ExpertiseContainer>
        <UnderlineHeader>Expertise</UnderlineHeader>
        <div className="feature">
          <div className="feature-img">
            <img src={Backslash} />
          </div>
          <div className="feature-text">
            <h4>
              <Link to="services/conseil-et-planning-stratégiques">
                Stratégie
              </Link>
              ,{" "}
              <Link to="services/branding">
                Brand leadership et Digital Marketing
              </Link>
            </h4>
            <p>
              Définissez une stratégie de marque pertinente, développez des
              campagnes publicitaires innovantes auprès de vos audiences sur
              tous les canaux de communication.
            </p>
          </div>
        </div>
        <div className="feature">
          <div className="feature-img">
            <img src={Backslash} />
          </div>
          <div className="feature-text">
            <h4>
              {" "}
              <Link to="services/communication-corporate-relation-publics-et-communication-d’influence">
                RP, Com’ Corporate et Influence
              </Link>
              ,{" "}
              <Link to="services/social-listening-et-e-réputation">
                E-réputation
              </Link>
              , <Link to="services/veille-stratégique">Veille Stratégique</Link>{" "}
            </h4>
            <p>
              Renforcez votre crédibilité auprès de vos publics et des leaders
              d’opinion, agissez rapidement pour protéger votre réputation et
              résoudre les crises.
            </p>
          </div>
        </div>
        <div className="feature">
          <div className="feature-img">
            <img src={Backslash} />
          </div>
          <div className="feature-text">
            <h4>
              <Link to="services/conseil-et-gestion-de-la-communication-évènementielle">
                Gestion et Communication Évènementielle
              </Link>
              , <Link to="services/marketing-direct"> Marketing Direct</Link>
            </h4>
            <p>
              Concrétisez le potentiel de votre marque en intervenant sur le
              terrain, au plus proche de vos audiences et consommateurs.
            </p>
          </div>
        </div>
      </ExpertiseContainer>
    </Container>
  );
};
export default Expertise;
