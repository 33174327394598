import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";

import Layout from "../components/Layout";
import Expertise from "../components/home/expertise";
import styled from "styled-components";
import Logos from "../components/home/logos";
import About from "../components/home/about";
import ProjectsHome from "../components/home/projects";
import { Helmet } from "react-helmet";
const Button = styled.button`
  background: var(--tbwa-yellow);
  font-size: 18px;
  padding: 10px 20px;
  color: black;
  @media (max-width: 800px) {
    margin-bottom: -20px;
  }
`;

const Hero = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)),
    url(${props => props.backgroundImage});
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  color: white;
  height: 100vh;
  position: relative;
  img {
    position: absolute;
    bottom: -8rem;
    right: 350px;
    width: 200px;
  }
  @media (max-width: 800px) {
    img {
      display: none;
    }
  }
  .title {
    font-size: 60px;
  }
  .hero-message {
    max-width: 800px;
  }

  @media (max-width: 800px) {
    height: auto;
    text-align: center;
    .title {
      color: white;
      font-size: 36px;
    }
    .hero-message {
      max-width: 100%;
    }
    .subtitle {
      font-size: 22px;
      display: none;
    }
  }
`;
class IndexPageTemplate extends React.Component {
  constructor() {
    super();
    this.state = {
      isMobile: false
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.handleWindowResize.bind(this));
    const width = window.innerWidth;

    if (width < 700) {
      this.setState({
        isMobile: true
      });
    }
  }

  handleWindowResize() {
    const { innerWidth } = window;
    if (innerWidth < 700) {
      this.setState({
        isMobile: true
      });
    } else {
      this.setState({
        isMobile: false
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }
  render() {
    const { image, title,heading } = this.props;
    const { isMobile } = this.state;
    return (
      <>
         <Helmet>
          <html lang="fr" />
          <title>TBWA\ DJAZ</title>
          <meta property="og:image" content={`https://www.tbwadjaz.com${image.childImageSharp.fluid.src}`} data-react-helmet="true" />
          <meta name="description" content="Nous sommes une agence de conseil en communication en Afrique du nord membre du réseau mondial TBWA" />
          <meta property="og:type" content="business.business" />
          <meta property="og:title" content="TBWA\ DJAZ" />
          <meta property="og:url" content="/http://tbwadjaz.com" />
        </Helmet>
        <Hero
          className=""
          backgroundImage={
            !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          }
        >
          <div className="hero-message">
            <h1 className="title">
             {title}
            </h1>
           <blockquote className="subtitle">{heading}</blockquote>
            <Link to="/services">
              <Button>En savoir plus</Button>
            </Link>
          </div>
        </Hero>
        {isMobile && <ProjectsHome visibleSlides={1} slideHeight={550} />}

        {!isMobile && <ProjectsHome visibleSlides={2} slideHeight={500} />}
        <Expertise />
        <Logos />
        <About />
      </>
    );
  }
}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array
  })
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        subheading={frontmatter.subheading}
        heading={frontmatter.heading}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
      }
    }
  }
`;
