import React from "react";
import styled from "styled-components";
import { Container, UnderLine, Button } from "../global-style";
import useProjectsdata from "../SiteProjects";
import useServicesData from "../SiteServices";
import Arrow from "../../img/svg/arrow.svg";
import ArrowLeft from "../../img/svg/arrow-left.svg";

import {
  CarouselProvider,
  Slider as PureSlider,
  Slide as PureSlide,
  ButtonBack,
  ButtonNext
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import Img from "gatsby-image";
import { Link } from "gatsby";

const MainProjectsContainer = styled.section`
  background: lighblue;
`;
const Box = styled.div`
  position: relative;
  overflow:hidden;

  .overlay {
    position: absolute;
    padding: 2rem;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--tbwa-overlay);
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    img {
      width: 120px;
    }
  }
  .services{
    display:flex;
    justify-content:flex-start;
    flex-direction:column;
    align-items:flex-start;
    span{
      font-size: 0.6rem;
    }
  }
  .wrapper-box:hover .overlay {
    opacity: 1;
  }
`;
const SliderContainer = styled.div`
  padding: 0.5rem;
  a {
    color: white;
  }
  a:hover {
    color: var(--tbwa-yellow);
  }

  p {
    font-size: 0.8rem;
  }
`;

const ProjectsHome = props => {
  const projects = useProjectsdata();
  const services = useServicesData();
  const findServiceSlug = title => {
    const result = services.find(service => {
      return service.node.frontmatter.title === title;
    });
    return result.node.fields.slug;
  };

  if (projects.length > 0) {
    return (
      <MainProjectsContainer>
        <Container>
          <h1>Projets</h1>
          <UnderLine />
        </Container>

        <CarouselProvider
          isPlaying
          naturalSlideWidth={600}
          naturalSlideHeight={props.slideHeight}
          totalSlides={projects.length}
          visibleSlides={props.visibleSlides}
          style={{ marginTop: "2rem" }}
        >

          <PureSlider>
            {projects.map((project, index) => {
              return (
                <PureSlide index={index}>
                  <SliderContainer>
                    <Box>
                      <div className="wrapper-box">
                        <Link to={project.node.fields.slug}>
                          <Img
                            fluid={
                              project.node.frontmatter.thumb.childImageSharp
                                .fluid
                            }
                          />

                          <div className="overlay">
                            <img
                              src={project.node.frontmatter.logo.publicURL}
                              className="logo"
                            />
                            <h4>
                              {project.node.frontmatter.title} /{" "}
                              {project.node.frontmatter.client}
                            </h4>
                            <div className="services">
                            {project.node.frontmatter.services?.map(
                              (service, index) => (
                                <>
                                  <span index={index}> {service} </span>
                                </>
                              )
                            )}
                            </div>
                          </div>
                        </Link>
                      </div>
                    </Box>
                  </SliderContainer>
                </PureSlide>
              );
            })}
          </PureSlider>
        </CarouselProvider>
      </MainProjectsContainer>
    );
  } else {
    return null;
  }
};
export default ProjectsHome;
